<!-- 红外探测器 -->
<template>
  <div id="infrared-detector">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>红外探测器</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="变配电站:">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" label="序号" prop="id">
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="name">
          </el-table-column>
          <el-table-column align="center" label="设备类型" prop="type">
          </el-table-column>
          <el-table-column align="center" label="开关状态" prop="status">
          </el-table-column>
          <el-table-column align="center" label="时间" prop="createTime">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate">
            <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" @click="deleteDialog = true"
                >删除</el-button>
            </div>
          </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page">首页</button>
          <el-pagination
            :current-page="requestParam.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot"
            prev-text="上一页"
            next-text="下一页"
            :total="total"
            @size-change="changeSize"
            @current-change="changeCurrent"
          >
          </el-pagination>
          <button class="tail-page">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input"></el-input>
            <span class="text">页</span>
            <button class="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="删除" :visible.sync="deleteDialog" width="436px">
      <div class="delete">确定要删除该文件吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="deleteDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      tableData: [
        {
          id: 1,
          name: "红外探测器",
          type: "设备类型",
          status: "关",
          createTime: "2023-12-12 18:12:12",
        },
        {
          id: 2,
          name: "红外探测器",
          type: "设备类型",
          status: "开",
          createTime: "2023-12-12 18:12:12",
        },
        {
          id: 3,
          name: "红外探测器",
          type: "设备类型",
          status: "关",
          createTime: "2023-12-12 18:12:12",
        },
      ],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      deleteDialog: false,
    };
  },

  mounted() {
    // this.queryPlatform();
  },

  methods: {
    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
      this.queryPlatform();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryPlatform();
    },
    queryPlatform() {
      eventApi.platform(this.requestParam).then((res) => {
        this.tableData = res.data;
        this.total = res.total;
      });
    },
  },
};
</script>
<style scoped>
#infrared-detector {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 26px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 查询表单 */
.query-form {
  width: 100%;
  min-height: 62px;
  background-color: rgba(6, 29, 74, 0.79);
  padding-left: 28px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0 11px 0 0;
}
.form :deep() .el-input .el-input__inner {
  width: 176px;
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}
::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__footer {
  padding-top: 0;
}
.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
</style>
